@tailwind base;
@tailwind components;
@tailwind utilities;
@keyframes shake {
  0%,
  100% {
    transform: rotate(0deg);
  }
  20%,
  60% {
    transform: rotate(-10deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}

.shake {
  animation: shake 0.5s infinite;
}
.react-datetime-picker .react-datetime-picker__wrapper {
  border: none;
}
.bar-chart canvas {
  height: 600px !important;
}
.chart-container {
  width: 100%;
  height: 500px;
  overflow: hidden;
}
.chart-container canvas{
  height: 450px !important;
}
